<template>
<div class="container">
  <div class="top-box">
    <img class="background" src="/img/chart/background.png" alt="">
    <div class="top">质量评估态势<span></span><p @click="chooseYear">{{currentYear}}<i class="el-icon-caret-bottom"></i></p></div>
    <div class="bottom">
     <p v-for="item in tabList" :key="item.id" :class="{active:item.id == activeName}" @click="tabChange(item)">{{ item.name }}</p>
    </div>
  </div>
  <div class="content-box">
    <van-tabs  v-model="activeTab" :ellipsis="false" title-active-color="#0083F5" title-inactive-color="#888888">
      <van-tab v-for="(item,index) in tabList2" :title="item" :name="index" :key="index"/>
    </van-tabs>
    <div class="main">
      <div class="main-item" v-show="activeTab==0">
        <div class="total-data">
          <div class="radio">
            <p @click="totalRadio=1;init()" :class="{active:totalRadio==1}">按机构数</p>
            <p @click="totalRadio=2;init()" :class="{active:totalRadio==2}">按次数</p></div>
          <div class="total-data-item" v-show="activeName == 0">
            <p>托育机构数量<span>{{ totalInfo.org_count_all }}</span></p>
            <p>自评提交{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.inspect_count_all}}</span></p>
            <p>监管他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.inspect_count_all_ta}}</span></p>
            <p v-show="totalRadio == 1">自评完成率<span>{{ totalInfo.inspect_ratio_all}}</span></p>
          </div>
          <div class="total-data-item" v-show="activeName == 1">
            <p>托育机构数量<span>{{  totalInfo.org_count_all }}</span></p>
            <p>自评提交{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.inspect_count_all}}</span></p>
            <p>自评评审完成{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.inspect_count_all_wancheng }}</span></p>
            <p v-show="activeName == 1 && totalRadio == 1">自评完成率<span>{{ totalInfo.inspect_ratio_all}}</span></p>
          </div>
          <div class="total-data-item" v-show="activeName == 2">
            <p>监管他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{  totalInfo.inspect_count_all_ta }}</span></p>
            <p>市级他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.shi_count_all}}</span></p>
            <p>省级他评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.sheng_count_all}}</span></p>
            <p>国家督评{{ totalRadio == '1' ? '机构数' : '次数' }}<span>{{ totalInfo.guojia_count_all}}</span></p>
          </div>
        </div>
        <div class="today-data">
          <div class="title-common"><span></span>{{ activeName == 2 ? "今日他评情况" : "今日自评情况" }}</div>
          <div class="number">
            <p> {{activeName == 2 ? "今日他评评估次数" : "今日自评评估次数"}}<span>{{todayData.today_count || 0}}</span></p>
            <p>环比昨日评估次数 
              <span>{{todayData.yesterday_ratio}}% 
                <i class="el-icon-top" style="color:red;"  v-if="todayData.yesterday_ratio>=0"></i>
                <i class="el-icon-bottom" style="color:#52C41A;" v-if="todayData.yesterday_ratio<0"></i>
              </span>
            </p>
          </div>
          <div class="average-number">
            <p>日均评估数量：<span>{{todayData.day_average}}</span></p>
          </div>
        </div>
        <div>
          <div class="title-common">
            <span></span>{{activeName !=2 ? "自评评审":"他评"}}等级分析
            <div class="select" @click="selectChoose('levelSelect')" v-show="activeName==2">
              <p v-show="levelSelect == '2'">市级他评</p>
              <p v-show="levelSelect == '4'">省级他评</p>
              <p v-show="levelSelect == '3'">国家督评</p>
            </div>
          </div>
          <div class="chart-level">
            <div>
              <p class="label" v-if="echartPieTotal && levelData">{{ (levelData.results_4 / echartPieTotal * 100).toFixed(2)}}%</p>
              <p class="label" v-else>0%</p>
              <div id="chart-levelA"></div>
              <p><b style="color: #0083F5;">A级</b><span>优秀</span>{{levelData?.results_4}}</p>
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && levelData">{{ (levelData.results_3 / echartPieTotal * 100).toFixed(2)}}%</p>
              <p class="label" v-else>0%</p>
              <div id="chart-levelB"></div>
              <p><b style="color: #4ECB73;">B级</b><span>合格</span>{{levelData?.results_3}}</p>
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && levelData">{{ (levelData.results_2 / echartPieTotal * 100).toFixed(2)}}%</p>
              <p class="label" v-else>0%</p>
              <div id="chart-levelC"></div>
              <p><b style="color: #FBD437;">C级</b><span>不合格</span>{{levelData?.results_2}}</p>
            </div>
            <div>
              <p class="label" v-if="echartPieTotal && levelData">{{ (levelData.results_1 / echartPieTotal * 100).toFixed(2)}}%</p>
              <p class="label" v-else>0%</p>
              <div id="chart-levelD"></div>
              <p><b style="color: #F2637B;">D级</b><span>需要重大改进</span>{{levelData?.results_1}}</p>
            </div>
          </div>
        </div>
        <div class="trend">
          <div class="title-common">
            <span></span>{{ activeName == 2 ? '他评' : '自评评审'}}趋势分析
          </div>
          <div class="top">
            <div class="dateType">
              <p :class="{active:dateType == 1}" @click="dateType = 1">日</p>
              <p :class="{active:dateType == 2}" @click="dateType = 2">周</p>
              <p :class="{active:dateType == 3}" @click="dateType = 3">月</p>
            </div>
            <p><span @click="chooseDate(1)">{{ startDate }}</span>~<span @click="chooseDate(2)">{{ endDate }}</span><i class="el-icon-caret-bottom"></i></p>
          </div>
          <div id="chart1" class="chart"></div>
        </div>
        <div class="intro">
          <div class="title-common"><span></span>评估内容概况</div>
          <div class="intro-list" v-if="rightData1.content_summary">
            <div class="intro-item" style="background-color:#CDDDFD;margin-right: 24px;" v-if="activeName != 2">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>机构自评
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_score}}</b><span>分</span></p>
              </div>
            </div>
            <div class="intro-item" style="background-color:#CDF3E4;" v-if="activeName != 2">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>自评评审
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_examine_total_time || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_examine_score}}</b><span>分</span></p>
              </div>
            </div>
            <div class="intro-item"  style="background-color:#CED4DE;" v-if="activeName == 0">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>监管他评
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time_ta || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_score_ta}}</b><span>分</span></p>
              </div>
            </div>
            <div class="intro-item" style="background-color:#CDDDFD;" v-if="activeName == 2">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/机构评估@2x.png"/></span>市级他评
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time_shi || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_score_shi}}</b><span>分</span></p>
              </div>
            </div>
            <div class="intro-item" style="background-color:#CDF3E4;" v-if="activeName == 2">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/督导评审@2x.png"/></span>省级他评
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time_sheng || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_score_sheng}}</b><span>分</span></p>
              </div>
            </div>
            <div class="intro-item" v-if="activeName == 2" style="background-color:#CED4DE;">
              <p class="intro-title">
                <span><img src="@/assets/indexNew/国家级督评价@2x.png"/></span>国家级督评
              </p>
              <div class="intro-data">
                <p>平均用时<br/><b>{{rightData1.content_summary.average_total_time_guojia || '00:00:00'}}</b></p>
                <p>平均得分<br/><b>{{rightData1.content_summary.average_score_guojia}}</b><span>分</span></p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-item main-item2" v-show="activeTab==1">
        <div class="main-item2-tab">
            <p :class="{active: regionActive == '1'}" @click="regionActive = '1'">区域整体分析</p>
            <p :class="{active: regionActive == '2'}" @click="regionActive = '2'">{{ activeName ==2 ? '他评' : '自评'}}区域分析</p>
          </div>
        <div class="main-item2-main" v-show="regionActive==2">
          <div class="province" @click="selectChoose('regionSelect')" v-show="activeName == 2">
            <p v-show="regionSelect == '2'">市级他评</p>
              <p v-show="regionSelect == '4'">省级他评</p>
              <p v-show="regionSelect == '3'">国家督评</p>
              <i class="el-icon-caret-bottom"></i>
          </div>
          
          <div class="title">
            <p v-if="role == 1 || role == 2">省份</p>
            <p v-if="role == 6">地市</p>
            <p v-if="role == 3">区县</p>
            <p v-if="activeName == 2">他评完成<br/>机构数量</p>
            <p v-if="activeName != 2">自评评审<br/>机构数量</p>
            <p>占比</p>
          </div>
          <div v-for="item in regionList">
            <p>{{ item.name }}</p>
            <p>{{ item.value }}</p>
            <p>{{ item.rate }}</p>
          </div>
        </div>
        <div class="main-item2-main" v-show="regionActive == 1">
          <div class="title" v-if="activeName != 2">
            <p v-if="role == 1 || role == 2">省份</p>
            <p v-if="role == 6">地市</p>
            <p v-if="role == 3">区县</p>
            <p>托育机构<br/>数量</p>
            <p>已自评<br/>机构数</p>
            <p>自评<br/>完成率</p>
            <p v-if="activeName == 0">监管他评机构数</p>
          </div>
          <div class="title" v-if="activeName == 2">
            <p>省份</p>
            <p>托育机构<br/>数量</p>
            <p>市级他评<br/>机构数</p>
            <p>省级他评<br/>机构数</p>
            <p>国家督评<br/>机构数</p>
          </div>
          <div v-for="item in mapData" v-if="activeName != 2">
            <p>{{ item.name }}</p>
            <p>{{ item.org_count }}</p>
            <p>{{ item.inspect_count }}</p>
            <p>{{ item.inspect_ratio }}</p>
            <p v-if="activeName == 0">{{ item.inspect_count_ta }}</p>
          </div>
          <div v-for="item in mapData" v-if="activeName == 2">
            <p>{{ item.name }}</p>
            <p>{{ item.org_count }}</p>
            <p>{{ item.shi_count }}</p>
            <p>{{ item.sheng_count }}</p>
            <p>{{ item.guojia_count }}</p>
          </div>
        </div>
      </div>
      <div class="main-item" v-show="activeTab==2">
        <div class="seven-data">
          <div class="title-common">
            <span></span>7大指标{{activeName == 2 ? '他评' : '自评'}}平均分分析
            <div class="select" @click="selectChoose('sevenSelect')" v-show="activeName==2">
              <p v-show="sevenSelect == '2'">市级他评</p>
              <p v-show="sevenSelect == '4'">省级他评</p>
              <p v-show="sevenSelect == '3'">国家督评</p>
            </div>
          </div>
          <ul>
            <li>
              <p>指标</p>
              <p>总分</p>
              <p>平均得分</p>
              <p>平均得分比重</p>
            </li>
            <li v-for="(item,index) of sevenData" :key="item.id">
              <p class="seven-name">
                <img :src="sevenImg[index]"/>
                {{item.name}}
              </p>
              <p>{{item.score*1}}</p>
              <p :class="{
                blue:parseFloat(item.score_ratio)>90 || parseFloat(item.score_ratio)==90, 
                green:parseFloat(item.score_ratio)>60 && parseFloat(item.score_ratio)<90 || parseFloat(item.score_ratio)==60,
                yellow:parseFloat(item.score_ratio)<60
                }">
                {{item.average_inspect_score*1}}
              </p>
              <p 
              :class="{
                blue:parseFloat(item.score_ratio)>90 || parseFloat(item.score_ratio)==90, 
                green:parseFloat(item.score_ratio)>60 && parseFloat(item.score_ratio)<90 || parseFloat(item.score_ratio)==60,
                yellow:parseFloat(item.score_ratio)<60
                }"
              >
                {{item.score_ratio}}
              </p>
            </li>
          </ul>
        </div>
        <div class="seven-time">
          <div class="title-common">
            <span></span>7大指标{{activeName == 2 ? '他评' : '自评'}}用时分析
            <div class="select" @click="selectChoose('timeSelect')" v-show="activeName==2">
              <p v-show="timeSelect == '2'">市级他评</p>
              <p v-show="timeSelect == '4'">省级他评</p>
              <p v-show="timeSelect == '3'">国家督评</p>
            </div>
          </div>
          <ul>
            <li>
              <p>指标</p>
              <p>平均用时</p>
              <p>占比</p>
            </li>
            <li v-for="(item,index) of sevenTimeData">
              <p> <img :src="sevenImg[index]"/>{{item.name}}</p>
              <p>{{item.average_object_total_date || '00:00:00'}}</p>
              <p>{{item.total_time_ratio}}%</p>
            </li>
          </ul>
        </div>
        <div class="seven-rate">
          <div class="title-common">
            <span></span>
            7大指标{{activeName == 2 ? '他评' : '自评'}}分值比重分析
            <div class="select" @click="selectChoose('rateSelect')" v-show="activeName==2">
              <p v-show="rateSelect == '2'">市级他评</p>
              <p v-show="rateSelect == '4'">省级他评</p>
              <p v-show="rateSelect == '3'">国家督评</p>
              
            </div>
          </div>
          <div id="r4"></div>
        </div>
      </div>
      <div class="main-item" v-show="activeTab==3">
        <div class="news">
          <div class="title-common"><span></span>评估实时动态</div>
          <van-list
            v-model="newsLoading"
            :finished="newsFinished"
            finished-text="没有更多了"
            @load="newsLoad"
          >
            <div class="news-item" v-for="item in newsList">
              <div class="news-left">
                <p>{{ item.myIndex }}.{{ item.organization_name }}</p>
                <p>{{ item.province_city_area }}</p>
                <p>{{ item.submit_date }}</p>
              </div>
              <div class="news-right">
                <p v-if="item.results == 4" style="color:#3AA1FF;">A级（优秀）</p>
                <p v-if="item.results == 3" style="color:#2FC25B">B级（合格）</p>
                <p v-if="item.results == 2" style="color:#FBD437">C级（不合格）</p>
                <p v-if="item.results == 1" style="color:#F5222D">D级（需要重大改进）</p>
                <p v-if="item.type == 4">省级他评</p>
                <p v-if="item.type == 3">国家督评</p>
                <p v-if="item.type == 2">市级他评</p>
                <p v-if="item.type == 1">机构自评</p>
              </div>
            </div>
          </van-list>
        </div>
      </div>
      <div class="main-item" v-show="activeTab==4">
        <div class="question-rand">
          <div class="title-common"><span></span>标星不合格排行榜</div>
            <div class="question-item" v-for="(item,index) in questionList">
              <span>{{index+1}}</span>
              <p>{{item.name}}</p>
            </div>
        </div>
      </div>
      <div class="myFooter">
        <p>技术支持：京学集团</p>
        <p>ICP备案编号：京ICP备1304369</p>
        <p><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502049964" target="_blank">京公安备：11010502049964号</a></p>
      </div>
    </div>
  </div>
  <van-popup v-model="popShow" position="bottom" :style="{ height: '40%' }">
    <van-picker :default-index="popIndex" show-toolbar  @cancel="popShow=false" :columns="yearList" @confirm="onConfirm" />
  </van-popup>
  <van-popup v-model="dateShow" position="bottom">
   <van-datetime-picker
      title="选择开始日期"
      v-model="currentDate1"
      :min-date="minDate"
      :max-date="maxDate"
      type="date"
      @cancel="dateShow = false"
      @confirm="dateConfirm"
    />
  </van-popup>
  <van-popup v-model="dateShow2" position="bottom">
     <van-datetime-picker
      title="选择结束日期"
      v-model="currentDate2"
      :min-date="minDate"
      :max-date="maxDate"
      type="date"
      @cancel="dateShow2 = false"
      @confirm="dateConfirm2"
    />
  </van-popup>
  <van-popup v-model="regionProvinceShow" position="bottom" :style="{ height: '40%' }">
    <van-picker  show-toolbar  @cancel="regionProvinceShow=false" :columns="regionProvinceList" @confirm="onRegionConfirm" />
  </van-popup>
  <van-popup v-model="selectShow" position="bottom" :style="{ height: '40%' }">
    <van-picker ref="myPicker" :default-index="selectIndex" show-toolbar  @cancel="selectShow=false" :columns="selectOptions" @confirm="selectConfirm" />
  </van-popup>
</div>
</template>

<script>
// role: 2国家 6省 4机构 3市级
import seven0 from '@/assets/indexNew/seven0.png'
import seven1 from '@/assets/indexNew/seven1.png'
import seven2 from '@/assets/indexNew/seven2.png'
import seven3 from '@/assets/indexNew/seven3.png'
import seven4 from '@/assets/indexNew/seven4.png'
import seven5 from '@/assets/indexNew/seven5.png'
import seven6 from '@/assets/indexNew/seven6.png'

import china from '@/assets/china_2.js'
let proviceMap = {}
china.features.map(e => {
  proviceMap[e.properties.id] = e.properties.name;
})
export default {
  name: "Main",
  data() {
    return {
      totalRadio:'1',
      totalInfo:{},
      todayData:{},
      selectShow: false,
      selectType: '',
      selectIndex: 0,
      selectOptions:[{
        value: '2',
        text: '市级他评'
      },{
        value: '4',
        text: '省级他评'
      },{
        value: '3',
        text: '国家督评'
      }],

      regionSelect: '2',
      levelSelect: '2',
      rateSelect: '2',
      timeSelect: '2',
      sevenSelect: '2',

      sevenData:{},
      sevenTimeData: {},
      sevenRateData:{},

      chartTrendData:[],
      role: '',
      name: '',
      username: '',
      tabList:[{
        name:'全部',
        id:0,
      },{
        name:'机构自评',
        id:1,
      },{
        name:'监管他评',
        id:2,
      }],
      activeTab:0,

      currentYear: '2024',
      yearList:['2024','2022'],
      popIndex: 0,
      popShow: false,

      tabList2:['概况统计','区域分析','7大指标分析','实时动态','不合格问题排行'],
      activeName: '0',

      chartA: '',
      chartB: '',
      chartC: '',
      chartD: '',
      levelData:{},
      echartPieTotal:0,

      dateType: 1,
      startDate:'',
      endDate:'',
      currentDate1: new Date(),
      currentDate2: new Date(),
      dateShow:false,
      dateShow2:false,
      minDate:'',
      maxDate:'',

      mapData:[],

      trendChart:'',
      rightData1:{},
      regionActive: 1,
      regionList:[],

      regionProvinceShow:false,
      regionProvince:'',
      regionProvinceList:[],

      sevenImg:[seven0,
      seven1,
      seven2,
      seven3,
      seven4,
      seven5,
      seven6,],
      chartSeven: '',

      newsLoading: false,
      newsFinished: true,
      newsList:[],
      newsPage: 1,
      newsSize: 20,

      questionPage: 1,
      questionSize: 10,
      questionLoading: false,
      questionFinished: false,
      questionList:[{
        name: "11-1、托小班（12-24个月）和托大班（24-36个月）的幼儿和便器的数量比例不小于5：1。"
      },{
        name: "11-2、托小班（12-24个月）和托大班（24-36个月）的幼儿和水龙头的数量比例不小于5：1。"
      },{
        name: "11-2、托小班（12-24个月）和托大班（24-36个月）的幼儿和水龙头的数量比例不小于5：1。"
      },{
        name: "11-2、托小班（12-24个月）和托大班（24-36个月）的幼儿和水龙头的数量比例不小于5：1。"
      }],
      questionPage: 1,
      questionSize: 10,
    };
  },
  computed: {},
  watch: {
    dateType:function(){
      this.getChartTrend()
    }
  },
  components: {},
  beforeRouteUpdate(to, from, next) {},
  async mounted() {
    this.name = localStorage.getItem('name')
    this.username = localStorage.getItem('username')
    this.role = localStorage.getItem('role') * 1

    let res = await this.$api({
          method: "get",
          url: "/api/inspect/data/get/years",
        });
        if (res.code == 0) {
          this.yearList = res.data.year_list.reverse()
          this.currentYear = this.yearList[0]
        }
    this.onConfirm(this.yearList[0])
  },
  actived() {

  },
  methods: {
    
    async getData(){
      this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
      });
      await this.init();
      await this.getChartTrend()
      await this.initRight1()
      await this.initLeft2()
      await this.getSeven()
      await this.getSevenTime()
      await this.getSevenRate()
      await this.getNews()
      await this.getQuestion()
      this.$toast.clear()
    },
    getDateFormat(time) {
      let d = new Date(time);
      return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate();
    },
    selectChoose(type){
      this.selectType = type
      this.selectIndex = this.selectOptions.findIndex(item=>item.value == this[type])
      this.selectShow = true
      if(this.$refs.myPicker){
        this.$refs.myPicker.setColumnIndex(0, this.selectIndex)
      }else{
        this.$nextTick(()=>{
          this.$refs.myPicker.setColumnIndex(0, this.selectIndex)
        })
      }
      
    },
    selectConfirm(value){
      this[this.selectType] = value.value
      if(this.selectType == 'levelSelect'){
        this.init()
      }else if(this.selectType == 'regionSelect'){
        this.initLeft2()
      }else if(this.selectType == 'rateSelect'){
        this.getSevenRate()
      }else if(this.selectType == 'timeSelect'){
        this.getSevenTime()
      }else if(this.selectType == 'sevenSelect'){
        this.getSeven()
      }
      this.selectShow =  false
    },
    formatDate(time, fmt = 'yyyy-MM-dd HH:mm:ss') {
      if (!(time instanceof Date)) return time
      const week = ['星期天', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
      const Q = ['一季度', '二季度', '三季度', '四季度']
      var o = {
        'M+': time.getMonth() + 1,
        'd+': time.getDate(),
        'H+': time.getHours(),
        'm+': time.getMinutes(),
        's+': time.getSeconds(),
        q: Math.floor((time.getMonth() + 3) / 3), // 季度
        Q: Q[Math.floor((time.getMonth() + 3) / 3) - 1], // 季度
        S: time.getMilliseconds(), // 毫秒
        w: time.getDay(), // 星期
        W: week[time.getDay()] // 星期
      }
      if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (time.getFullYear() + '').substr(4 - RegExp.$1.length))
      }
      for (var k in o) {
        if (new RegExp('(' + k + ')').test(fmt)) {
          fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length))
        }
      }
      return fmt
    },
    async init(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/index",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          data_type: this.totalRadio,
          city_type: this.levelSelect,
        }
      });
      console.log(res)
      if (res?.code == 0) {
        this.totalInfo = res.data.top_data;
        this.todayData = res.data.today_data
        this.levelData = res.data.results_arr
        this.mapData = res.data.province_data
        let t = this.levelData.results_1 + this.levelData.results_2 + this.levelData.results_3 + this.levelData.results_4;
        this.echartPieTotal = t
        this.$nextTick(()=>{
          this.setChartLevel()
        })
      } else {
        this.$message({
          message: res?.msg,
          type: "warning",
        });
      }
    },
    async initRight1(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.sevenSelect,
        }
      });
      if (res.code == 0) {
        this.rightData1 = res.data;
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async initLeft2(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/province/analysis",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.regionSelect,
        }
      });
      if (res.code == 0) {
        let regionList = res.data.province_list;
        for (const iterator of regionList) {
          // iterator.name = proviceMap[iterator.code]
          iterator.value = iterator.total;
          iterator.rate = iterator.org_ratio
        }
        this.regionList = regionList
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    setChartLevel() {
      this.chartA = this.$echarts.init(document.getElementById('chart-levelA'));
      this.chartB = this.$echarts.init(document.getElementById('chart-levelB'));
      this.chartC = this.$echarts.init(document.getElementById('chart-levelC'));
      this.chartD= this.$echarts.init(document.getElementById('chart-levelD'));
      let arr = this.levelData
      let t = this.echartPieTotal
      let option = {
        color: ['#1890FF', '#F0F2F5'],
        series: [{
          type: 'pie',
          radius: ['75%', '90%'],
          // selectedMode: 'single',
          avoidLabelOverlap: false,
          cursor: 'pointer',
          label: {
            show: false,
            position: 'center',
          },
          emphasis:{
            disabled: true,
          },
          selectedOffset: 2,
          select: {
            label: {
              show: false,
            },
          },
          
          labelLine: {
            show: false
          },
          colorBy: 'data',
          data: [{
            value: arr.results_4,
            name: 'A级'
          },
          {
            value: t ? t - arr.results_4 : 1,
            name: '其他'
          }]
      
        }]
      };
      this.chartA.setOption(option)
      let optionB = JSON.parse(JSON.stringify(option))
      optionB.color[0] = '#2FC25B'
      optionB.series[0].data = [{
            value: arr.results_3,
            name: 'B级'
          },
          {
            value: t ? t - arr.results_3 : 1,
            name: '其他'
          }]
      this.chartB.setOption(optionB)
      let optionC = JSON.parse(JSON.stringify(option))
      optionC.color[0] = '#FACC14'
      optionC.series[0].data = [{
            value: arr.results_2,
            name: 'C级'
          },
          {
            value: t ? t - arr.results_2 : 1,
            name: '其他'
          }]
      this.chartC.setOption(optionC)
      let optionD = JSON.parse(JSON.stringify(option))
      optionD.color[0] = '#F2637B'
      optionD.series[0].data = [{
            value: arr.results_1,
            name: 'D级'
          },
          {
            value: t ? t - arr.results_1 : 1,
            name: '其他'
          }]
      this.chartD.setOption(optionD)
    },
    async getChartTrend(type) {

      let month = new Date().getMonth() + 1
      let date = new Date().getDate()
      let today = new Date(this.currentYear+'-'+month+'-'+date)

      let date2 = new Date(new Date(this.currentYear+'/'+month+'/'+date).getTime() - (3600 * 24 * 6 * 1000))
      this.startDate = this.formatDate(date2,'yyyy-MM-dd')
      let week7Ago = new Date(Math.max(today.getTime() - (3600 * 24 * 6 * 1000 *7), new Date(this.currentYear + '-' + 1+'-'+1).getTime()))
      let month7Ago = month < 7 ? new Date(this.currentYear + '-' + 1+'-'+1) :
                       new Date(this.currentYear + '-' + (month-6)+'-'+date) 
      if(this.dateType == 1){
        this.startDate = this.formatDate(date2,'yyyy-MM-dd')
        }else if(this.dateType == 2){
          this.startDate = this.formatDate(week7Ago,'yyyy-MM-dd')
        }else if(this.dateType == 3){
          this.startDate = this.formatDate(month7Ago,'yyyy-MM-dd')
        }
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/trend/analysis",
        data: {
          inspect_type: this.activeName,
          year: this.currentYear,
          type: this.dateType,
          start_date: this.getDateFormat(this.startDate),
          end_date: this.getDateFormat(this.endDate),
        }
      });
      if (res.code == 0) {
        console.log(this.dateType)
        if(this.dateType == 2){
          res.data.data.forEach((item,index)=>{
            if(index==0){
              item.weekDate = item.date +'-'+this.getWeekDates(item.date).end.replace(/-/g,'/')
            }else if(index==res.data.data.length-1){
              item.weekDate = this.getWeekDates(item.date).start.replace(/-/g,'/') + '-' + item.date.replace(/-/g,'/')
            }else{
              item.weekDate = this.getWeekDates(item.date).start.replace(/-/g,'/')+'-'+this.getWeekDates(item.date).end.replace(/-/g,'/')
            }
          })
        }
        this.chartTrendData = res.data.data;
        this.setChartTrend()

      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    getWeekDates (date) {
      // 获取当前日期
      var today = new Date(date);
      // 获取当前是星期几
      var day = today.getDay();
      if (day == 0) {
        // 计算本周第一天的日期
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day - 6);
        // 计算本周最后一天的日期
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day);
      } else {
        var startDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 1);
        var endDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() - day + 7);
      }
      // 返回本周的日期范围
      return { start: this.getDateFormat(startDate), end: this.getDateFormat(endDate) };
    },
    async getNews(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/real/time",
        data: {
          page: this.newsPage,
          limit: this.newsSize,
          year: this.currentYear,
          inspect_type: this.activeName,
        }
      });
      if (res.code == 0) {
        res.data.real_time.forEach((item,index)=>{
          item.myIndex = res.data.real_time.length - index
        })
        this.newsList = res.data.real_time

        // console.log(res)
        // if(res.data.real_time.length){
        //   for(let i = 0; i < res.data.real_time.length; i+=10){
        //     this.newsList.push(res.data.real_time.slice(i, i+10))
        //   }
        // }else{
        //   this.newsFinished = true
        // }
       
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
	async getQuestion(type) {
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/unqualified",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
        }
      });
      if (res.code == 0) {
        this.rightData2 = res.data;
        this.questionList = res.data.object_list.slice(0,10)
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    chooseDate(type){
      if(type == 1){
        let week7Ago = new Date(Math.max(this.today.getTime() - (3600 * 24 * 6 * 1000 *7), new Date(this.currentYear + '-' + 1+'-'+1).getTime()))
        let month7Ago = month < 7 ? new Date(this.currentYear + '-' + 1+'-'+1) :
                       new Date(this.currentYear + '-' + (month-6)+'-'+date) 
        console.log(this.startDate,'start')
        this.currentDate1 = new Date(this.startDate.replace(/-/g,'/'))
        this.minDate = new Date(this.currentYear + '/01/01')
        this.maxDate = new Date(this.endDate.replace(/-/g,'/'))
        this.dateShow = true

      }else{
        this.currentDate2 = new Date(this.endDate.replace(/-/g,'/'))
        this.minDate = new Date(this.startDate.replace(/-/g,'/'))
        this.maxDate = new Date(this.currentYear + '/12/31')
        this.dateShow2 = true
      }
    },
    dateConfirm(){
      let dateCurrent = new Date(this.currentDate1)
      let monthCurrent = new Date(dateCurrent).getMonth() + 1
      let date = new Date(dateCurrent).getFullYear()+'-'+ monthCurrent + '-' + new Date(dateCurrent).getDate()
      this.startDate = date
      this.getChartTrend()
      this.dateShow = false
    },
    dateConfirm2(){
      let dateCurrent = new Date(this.currentDate2)
      let monthCurrent = new Date(dateCurrent).getMonth() + 1
      let date = new Date(dateCurrent).getFullYear()+'-'+ monthCurrent + '-' + new Date(dateCurrent).getDate()
      this.endDate = date
      this.getChartTrend()
      this.dateShow2 = false
    },
    setChartTrend() {
      let list = this.chartTrendData.map(item=>{
        let v= {
          ...item,
          date: item.date,
          value: this.activeName == 2 ? parseFloat(item.inspect_num_shi) + parseFloat(item.inspect_num_sheng) + parseFloat(item.inspect_num_guojia) : item.inspect_num
        }
        return v
      });
      this.trendChart = this.$echarts.init(document.getElementById('chart1'));
      let option = {
        grid: {
          left: 30,
          bottom: 66,
          top: 30
        },
        tooltip: {
          trigger: 'item',
          formatter: '{b}<br/>{a}：{c}',
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          },
          formatter: (params)=> {
            let name = ''
            let date = params.data.date.replace(/-/g, '/')+' '
            let data = params.data
            console.log(data)
            if(this.activeName !=2 ){
              name = this.dateType == 3 ? `${date}提交自评次数 ${data.value}` : (this.dateType == 2 ? `${params.data.weekDate} 提交自评次数 ${data.value}` : `${date}提交自评次数 ${data.value}`)
            }else{
              if(this.dateType == 1){
                name = `${date}市级他评次数 ${data.inspect_num_shi}<br/>${date}省级他评次数 ${data.inspect_num_sheng}<br/>${date}国家督评次数 ${data.inspect_num_guojia}`
              }else if(this.dateType == 2){
                name = `${params.data.weekDate} 市级他评次数 ${data.inspect_num_shi}<br/>${params.data.weekDate} 省级他评次数 ${data.inspect_num_sheng}<br/>${params.data.weekDate} 国家督评次数 ${data.inspect_num_guojia}`
              }else if(this.dateType == 3){
                name = `${date}市级他评次数 ${data.inspect_num_shi}<br/>${date}省级他评次数 ${data.inspect_num_sheng}<br/>${date}国家督评次数 ${data.inspect_num_guojia}`
              }
            }
            console.log(name)
            return name
          },
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: list.map(e => {
            return e.date.slice(5, 10) + (this.dateType == 3 ? '月' : '')
          })
        },
        yAxis: {
          type: 'value',
          minInterval: 1
        },
        series: [{
          // name: (this.dateType == 3 ? '本月评估的机构' : (this.dateType == 2 ? '本周评估的机构' : '今日评估的机构')),
          data: list,
          type: 'line',
          itemStyle:{
            color: '#1890FF'
          }
        }]
      };
      option && this.trendChart.setOption(option);
    },
    chooseRegionProvince(){
      this.regionProvinceShow = true
    },
    onRegionConfirm(value){
      this.regionProvince = value
      this.regionProvinceShow = false
    },
    async getSeven(){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/score",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.timeSelect
        }
      });
      if (res.code == 0) {
        this.sevenData = res.data.object_list.slice(0,7);
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getSevenTime(type){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/time",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.timeSelect,
        }
      });
      if (res.code == 0) {
        this.sevenTimeData = res.data.object_list.slice(0,7);
        
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    async getSevenRate(type){
      let res = await this.$api({
        method: "get",
        url: "/api/inspect/data/object/analysis/average",
        data:{
          inspect_type: this.activeName,
          year: this.currentYear,
          city_type: this.rateSelect,
        }
      });
      if (res.code == 0) {
        this.sevenRateData = res.data.object_list.slice(0,7);
        console.log(res)
        this.$nextTick(()=>{
          this.setChartSeven()
        })
      } else {
        this.$message({
          message: res.msg,
          type: "warning",
        });
      }
    },
    setChartSeven() {
      this.chartSeven = this.$echarts.init(document.getElementById('r4'));
      let arr = this.sevenRateData;
      for (const iterator of arr) {
        iterator.value = iterator.total_time_ratio * 1;
      }
      let option = {
        color:['#1890FF', '#5AD8A6'],
        grid: {
          left: 35,
          bottom: 40,
          top: 50,
          right: 0
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            crossStyle: {
              color: '#999'
            }
          },
          showDelay: 0,
          renderMode: 'html',
          transitionDuration: 0.2,
          className: 'echarts-tooltip',
          backgroundColor: 'rgba(0,0,0,0.7)',
          borderColor: 'rgba(50,50,50,0)',
          textStyle: {
            color: '#fff',
          }
        },
        legend: {
          data: ['得分比重', '分值比重']
        },
        xAxis: [{
          type: 'category',
          data: arr.map(e => {
            return '' + e.name.slice(0, 2) + '\n' + (e.name.slice(2, 4)) + ''
          }),
          nameTextStyle: {
            width: 4
          },
          axisPointer: {
            type: 'shadow'
          }
        }],
        yAxis: [{
          type: 'value',
          name: '',
          min: 0,
          max: 25,
          interval: 5,
          axisLabel: {
            formatter: '{value}%'
          }
        }],
        series: [{
            left: 10,
            name: '得分比重',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: arr.map(e => {
              return e.inspect_score_ratio.replace('%', '')
            })
          },
          {
            left: 10,
            name: '分值比重',
            type: 'bar',
            tooltip: {
              valueFormatter: function (value) {
                return value + '%';
              }
            },
            data: arr.map(e => {
              return e.object_score_ratio.replace('%', '')
            })
          }
        ]
      };
      option && this.chartSeven.setOption(option);
    },
    newsLoad(){
      this.newsPage++
      this.getNews()
    },
    questionLoad(){
      this.questionPage++
      this.getQuestion()
    },
    tabChange(item){
      this.activeName = item.id
      this.getData()
    },
    chooseYear(){
      this.popIndex = this.yearList.findIndex(item=>item == this.currentYear)
      this.popShow = true
    },
    onConfirm(value){
      this.currentYear = value

      let month = new Date().getMonth() + 1
      let date = new Date().getDate()

      this.endDate = this.formatDate(new Date(value+'/'+month+'/'+date),'yyyy-MM-dd')
      let date2 = new Date(new Date(value+'/'+month+'/'+date).getTime() - (3600 * 24 * 6 * 1000))
      this.startDate = this.formatDate(date2,'yyyy-MM-dd')

      this.popShow = false
      this.getData()
    }
  },
};
</script>

<style lang="scss">
.container {
  height: 100%;


  .top-box {
    position: fixed;
    z-index: 2;
    top: 0;
    width: 100%;
    height: 114px;
    // background-image: url('/img/main/background.png');
    // background-size: 100% 125px;

    .background {
      position: absolute;
      left: 0;
      z-index: -1;
      width: 100%;
      height: 125px;
    }

    .top {
      margin: 22px 0 12px 20px;
      text-align: left;
      font-size: 20px;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 28px;
      display: flex;
      align-items: center;
      span{
        width: 1px;
        height: 12px;
        background-color: #FFFFFF;
        display: block;
        margin: 0 8px;
      }
      p{
        font-size: 14px;
        i{
          margin-left: 8px;
        }
      }
    }

    .bottom {
      z-index: 99;
      margin: 0px 12px 0 20px;
      display: flex;
      align-items: center;
      p{
        height: 24px;
        background: #77BAFF;
        border-radius: 12px;
        padding: 0 12px;
        line-height: 24px;
        color: #F8FCFF;
        font-size: 12px;
        margin-right: 8px;
      }
      p.active{
        background-color: #fff;
        color: #000000;
      }
    }
  }
  .content-box {
    position: absolute;
    top: 100px;
    z-index: 99;
    height: calc(100% - 100px);
    width: 100%;
    background: #F7F8FA;
    border-radius: 10px 10px 0px 0px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    font-size: 14px;
    text-align: left;
    .main{
      flex: 1;
      overflow-y: auto;
      .main-item > div{
        background-color: #fff;
        margin-top: 10px;
      }
      .main-item2{
        display: flex;
        overflow: hidden;
        flex-direction: column;
        .main-item2-tab{
          width: 100%;
          background: #E5EEFF;
          border-radius: 10px 10px 0px 0px;
          overflow: hidden;
          display: flex;
          p{
            flex: 1;
            padding: 8px 0;
            color: #000;
            line-height: 24px;
            text-align: center;
          }
          p.active{
            background-color: #fff;
            border-radius: 10px 10px 0px 0px;
          }
        }
        .main-item2-main{
          flex: 1;
          overflow-y: auto;
          padding: 10px 10px 0 10px;
          >div{
            width: 100%;
            height: 48px;
            align-items: center;
            display: flex;
            justify-content: space-between;
            &:not(:first-of-type){
              border-bottom: 1px solid #EEEEEE;
            }
          }
          .province{
            border: 1px solid #EEEEEE;
            padding: 0 10px;
            border-radius: 4px;
            margin-bottom: 10px;
          }
          .title{
            background: #FAFAFA;
            border-radius: 4px 4px 0px 0px;
          }
          p{
            text-align: center;
            padding: 0 10px;
            line-height: 16px;
            flex: 1;
          }
        }
      }
    }
    .title-common{
      height: 44px;
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      color: #000000;
      line-height: 24px;
      span{
        width: 3px;
        height: 12px;
        background: #0083F5;
        display: block;
        margin-left: 10px;
        margin-right: 8px;
      }
      .select{
        margin-left: auto;
        margin-right: 10px;
        p{
          border: 1px solid #efefef;
          padding: 0 8px;
          font-size: 14px;
          height: 24px;
          border-radius: 3px;
        }
      }
    }
    .total-data{
      color: rgba(0,0,0,0.45);
      .radio{
        display: flex;
        justify-content: flex-end;
        padding-top: 10px;
        padding-right: 20px;
        p{
          padding: 0 10px;
          height: 30px;
          line-height: 30px;
          &:first-of-type{
            border:  1px solid #dedede;
            border-radius: 4px 0 0 4px;
            
          }
          &:last-of-type{
            border-radius: 0 4px 4px 0;
            border-top:  1px solid #dedede;
            border-right:  1px solid #dedede;
            border-bottom:  1px solid #dedede;
          }
        }
        p.active{
          background: #0083F5;
          color: #fff;
          border-color: #0083F5;
        }
      }
      .total-data-item p{
        width: 50%;
        display: inline-block;
        padding: 10px;
        line-height: 22px;
        span{
          color: rgba(0,0,0,0.85);
          font-size: 24px;
          display: block;
          line-height: 38px;
        }
      }      
    }
    .today-data{
      overflow: hidden;
      .number{
        display: flex;
        p{
          flex: 1;
          padding: 10px;
          line-height: 22px;
          color: rgba(0,0,0,0.45);
          span{
            color: rgba(0,0,0,0.85);
            font-size: 24px;
            display: block;
            line-height: 38px;
          }
        }
      }
      .average-number{
        margin: 10px;
        width: calc( 100% - 20px);
        height: 32px;
        line-height: 32px;
        background: #F7F8FA;
        border-radius: 4px;
        padding: 0 10px;
        color: rgba(0,0,0,0.65);
        span{
          color: rgba(0,0,0,0.85);
        }
      }
    }
    .chart-level{
      overflow: hidden;
      >div{
        width: 50%;
        padding: 15px 10px;
        height: 96px;
        display: flex;
        float: left;
        align-items: center;
        position: relative;
        .label{
          position: absolute;
          top: 50%;
          transform: translate(-50%,-50%);
          left: 43px;
          text-align: center;
          font-size: 12px;
          color: #2C3542;
        }
        >div{
          width: 66px;
          height: 66px;
          margin-right: 10px;
        }
        p{
          color: #333333;
          line-height: 22px;
          b{
            display: block;
            font-size: 16px;
            font-weight: 500;
          }
          span{
            color: #888888;
            display: block;
          }
        }
      }
    }
    .trend{
      .top{
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .dateType{
          display: flex;
          p{
            width: 36px;
            height: 24px;
            background: #F7F8FA;
            border-radius: 12px;
            line-height: 24px;
            text-align: center;
            margin-right: 8px;
          }
          p.active{
            background: #0083F5;
            color: #fff;
          }
        }
        >p{
          color: rgba(0,0,0,0.65);
          i{
            color: #595959;
            margin-left: 8px;
          }
        }
      }
      #chart1{
        height: 212px;
        width: calc(100% - 10px);
        margin: 0 0 20px 10px;
      }
    }
    .intro{
      .intro-list{
        overflow: hidden;
      }
      .intro-item{
        width: calc(100% - 20px);
        margin: 10px;
        height: 100px;
        background: #CDDDFD;
        border-radius: 10px;
        display: flex;
        align-items: center;
        .intro-title{
          width: 90px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: rgba(0,0,0,0.85);
          line-height: 20px;
          padding-left: 10px;
          font-weight: 500;
          span{
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 8px;
            border-radius: 50%;
            background-color: #fff;
            img{
              width: 24px;
            }
          }
         
        }
        .intro-data{
          display: flex;
          justify-content: space-evenly;
          flex: 1;
          line-height: 22px;
          p{
            flex: 1;
            padding-left: 40px;
            color: rgba(0,0,0,0.45);
            b{
              line-height: 38px;
              color: rgba(0,0,0,0.85);
              font-size: 24px;
              span{
                color: rgba(0,0,0,0.65);
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .seven-data,.seven-time{
      ul{
        padding: 10px 10px 0 10px;
        li{
          width: 100%;
          display: flex;
          align-items: center;
          color: rgba(0,0,0,0.65);
          font-size: 12px;
          p{
            padding: 0 10px;
            flex: 1;
            &:first-of-type{
              flex: 2;
            }
            &:last-of-type{
              flex: 0 0 90px;
              padding: 0;
              flex-shrink: 0;
              text-align: center;
            }
            &:nth-of-type(2){
              padding-left: 20px;
            }
          }
        }
        li:first-of-type{
          height: 32px;
          background: #FAFAFA;
          border-radius: 4px 4px 0px 0px;
        }
        li:not(:first-of-type){
          height: 48px;
          &:not(:last-of-type){
            border-bottom: 1px solid #EEEEEE;
          }
          img{
            width: 24px;
            margin-left: 4px;
            margin-right: 8px;
          }
          p:first-of-type{
            display: flex;
            align-items: center;
            color: rgba(0,0,0,0.85);
          }
        }
      }
    }
    .seven-data{
      p.blue{
        color: #0083F5;
      }
      p.green{
        color: #4ECB73;
      }
      p.yellow{
        color: #FBD437;
      }
    }
    .seven-time ul li p{
      flex: 1 !important;
    }
    .seven-rate #r4{
      height: 422px;
      width: calc(100vw - 20px);
      margin: 10px;
    }
    .news-item{
      border-bottom: 1px solid #EEEEEE;
      margin: 0 12px;
      &:first-of-type{
        margin-top: 10px;
      }
      display: flex;
      padding: 10px 0;
      justify-content: space-between;
      .news-left{
        p{
          color: #888888;
        }
        p:first-of-type{
          color: rgba(0,0,0,0.85);
          line-height: 20px;
          font-weight: 500;
          margin-bottom: 2px;
        }
        p:nth-of-type(2){
          line-height: 17px;
          font-size: 12px;
          margin-top: 2px;
          margin-bottom: 4px;
        }
        p:last-of-type{
          font-size: 10px;
        }
      }
      .news-right{
        line-height: 22px;
        text-align: right;
        font-size: 14px;
        p:last-of-type{
          color: rgba(0,0,0,0.65);
          font-size: 12px;
          margin-top: 7px;
        }
      }
    }
    .question-item{
      display: flex;
      padding: 10px;
      p{
        line-height: 22px;
        color: rgba(0,0,0,0.65);
      }
      span{
        display: block;
        flex-shrink: 0;
        width: 20px;
        height: 20px;
        margin-right: 10px;
        font-size: 12px;
        background: #F0F2F5;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
      }
      &:nth-of-type(-n+4) span{
        background: #1890FF;
        color: #fff;
      }
    }
    .myFooter{
      text-align: center;
      padding: 40px 0;
      color: rgba(0,0,0,0.45);
      font-size: 12px;
      line-height: 16px;
      p{
        margin-bottom: 4px;
      }
      a{
        color: rgba(0,0,0,0.45);
      }
    }
  }
}
</style>

<style lang="scss">
  .van-tabs__line{
    background-color: transparent;
  }
</style>
